<template>
    <div class="backdrop" @click.self="onClose">
        <div class="report-modal">
            <div>
                <RangeSelector @on-view="onView" @on-close="onClose" />
            </div>
            <div style="display:flex; flex-direction: columns;justify-content:space-between;background-color: #599f00;">
                <div class="report-title">{{ reportTitle }}</div>
                <div style="display:flex; flex-direction: columns;justify-content:flex-end;background-color: #2196e2;" v-show="dataReport != null">
                    <div class="report-export" @click="savePdf">PDF</div>
                    <div class="report-export" @click="exportCsv">CSV</div>
                </div>
            </div>

            <div style="background: white; overflow-y: auto;height: 90%;" v-show="dataReport != null">
                <table class="report-div-table" cellspacing="0">
                    <tr class="report-div-table-row">
                        <th class="report-div-table-col" style="width: 12%">Date Time</th>
                        <th class="report-div-table-col" style="width: 8%">Sale Type</th>
                        <th class="report-div-table-col" style="width: 8%">Location</th>
                        <th class="report-div-table-col" style="width: 8%">User</th>
                        <th class="report-div-table-col" style="width: 10%">Item</th>                        
                        <th class="report-div-table-col" style="width:8%;text-align:right">Cash</th>
                        <th class="report-div-table-col" style="width:8%;text-align:right">Credit</th>
                        <th class="report-div-table-col" style="width:8%;text-align:right">Total</th>
                        <th class="report-div-table-col" style="width:8%;text-align:right" v-if="showFees">Fees</th>
                        <th class="report-div-table-col" style="width:8%;text-align:right" v-if="showFees">Net Total</th>
                    </tr>

                    <tr v-for="row in dataReport" :key="row.description" class="report-div-table-row">
                        <td class="report-div-table-col">{{ row.timestampDisplay }}</td>
                        <td class="report-div-table-col">{{ Utilities.toCartProcessingTypesDisplayName(row.saleType) }}</td>
                        <td class="report-div-table-col">{{ row.location }}</td>
                        <td class="report-div-table-col">{{ row.user }}</td>
                        <td class="report-div-table-col">{{ row.item }}</td>                        

                        <!-- Cash -->
                        <td class="report-div-table-col" style="text-align:right">{{ Utilities.toCurrency(row.cash) }}</td>

                        <!-- Credit -->
                        <td class="report-div-table-col" style="text-align:right">{{ Utilities.toCurrency(row.creditCard) }}</td>
                        
                        <!-- Total -->
                        <td class="report-div-table-col" style="text-align:right">{{ Utilities.toCurrency(row.cash + row.creditCard) }}</td>

                        <!-- Fees -->
                        <td class="report-div-table-col" style="text-align:right" v-if="showFees">{{ Utilities.toCurrency(row.serviceFeeCreditCardAmount) }}</td>

                        <!-- Net Total -->
                        <td class="report-div-table-col" style="text-align:right" v-if="showFees">{{ Utilities.toCurrency(row.cash + row.creditCard - row.serviceFeeCreditCardAmount) }}</td>
                    </tr>

                    <tr class="report-div-table-row" style="font-weight: bold;">
                        <td class="report-div-table-col">TOTALS</td>
                        <td class="report-div-table-col"></td>
                        <td class="report-div-table-col"></td>
                        <td class="report-div-table-col"></td>
                        <td class="report-div-table-col"></td>                        

                        <!-- Cash -->
                        <td class="report-div-table-col" style="text-align:right">{{ Utilities.toCurrency(totalRow.cash) }}</td>

                        <!-- Credit -->
                        <td class="report-div-table-col" style="text-align:right">{{ Utilities.toCurrency(totalRow.creditCard) }}</td>                        

                        <!-- Total -->
                        <td class="report-div-table-col" style="text-align:right">{{ Utilities.toCurrency(totalRow.cash + totalRow.creditCard) }}</td>

                        <!-- Fees -->
                        <td class="report-div-table-col" style="text-align:right" v-if="showFees">{{ Utilities.toCurrency(totalRow.serviceFeeCreditCardAmount) }}</td>

                        <!-- Net Total -->
                        <td class="report-div-table-col" style="text-align:right" v-if="showFees"> {{ Utilities.toCurrency(totalRow.cash + totalRow.creditCard - totalRow.serviceFeeCreditCardAmount) }}</td>
                    </tr>
                                        
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import RangeSelector from "@/views/components/Reports/RangeSelector.vue";
import apiReport from "@/api/apiReport";
import { storage } from "@/common/storage";
import { ref } from "@vue/reactivity";
import { getCurrentInstance } from "@vue/runtime-core";
import PdfHelper from "@/common/pdfHelper";
import Helper from "@/common/helper";
import Utilities from "@/common/utilities";
import ReportingIncomeLogModel from "@/common/models/Reporting/ReportingIncomeLogModel";

export default {
    components: {
        RangeSelector
    },
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);
        const dataReport = ref();
        const totalRow = ref(new ReportingIncomeLogModel());
        const showFees = ref(false);

        const reportTitle = ref("Income Logs");
        const reportfilename = ref("IncomeLogs");

        const onClose = () => {
            dataReport.value = null;
            emit("on-close");
        };

        const onView = async () => {
            try {
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading...");

                dataReport.value = await apiReport.incomeLogs(storage.getReportDateStart(), storage.getReportDateEnd());

                totalRow.value = new ReportingIncomeLogModel();
                totalRow.value.item = "Totals";
                if (dataReport.value.length > 0) {
                    totalRow.value.cash = dataReport.value.map((item) => item.cash).reduce((prev, next) => prev + next);
                    totalRow.value.creditCard = dataReport.value.map((item) => item.creditCard).reduce((prev, next) => prev + next);                    
                    totalRow.value.serviceFeeCreditCardAmount = dataReport.value.map((item) => item.serviceFeeCreditCardAmount).reduce((prev, next) => prev + next);
                    totalRow.value.serviceFeeCreditCardFixed = dataReport.value.map((item) => item.serviceFeeCreditCardFixed).reduce((prev, next) => prev + next);
                    totalRow.value.serviceFeeCreditCardRate = totalRow.value.creditCard != 0 ? totalRow.value.serviceFeeCreditCardAmount / totalRow.value.creditCard : 0;

                    showFees.value = dataReport.value.map((item) => item.serviceFeeCreditCardRate).reduce((prev, next) => prev + next) != 0;
                }
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const exportCsv = () => {
            var csvData = [];
            dataReport.value.forEach((item) => {
                var row = {
                    Date__Time: item.timestampDisplay.toExcelSafe(),
                    Sale__Type: Utilities.toCartProcessingTypesDisplayName(item.saleType)?.toExcelSafe(),
                    Location: item.location.toExcelSafe(),
                    Item: item.item.toExcelSafe(),
                    Cash: item.cash,
                    Credit: item.creditCard,
                    Total: item.cash + item.creditCard
                };
                if (showFees.value) {
                    row.Fees = item.serviceFeeCreditCardAmount;
                    row.Net__Total = item.cash + item.creditCard - item.serviceFeeCreditCardAmount;
                }
                row.orderId = item.orderId;

                
                csvData.push(row);
            });
            Helper.exportCsv(csvData, reportfilename.value);
        };

        const savePdf = () => {
            var fontAdjust = 0;
            if (showFees.value) fontAdjust = -2;

            var positions = {
                xField1: 12,
                pField1: "left",

                xField2: 68 - (showFees.value ? 10 : 0),
                pField2: "left",

                xField3: 104 - (showFees.value ? 20 : 0),
                pField3: "left",

                xField4: 145 - (showFees.value ? 30 : 0),
                pField4: "left",

                xField5: 235 - (showFees.value ? 60 : 20),
                pField5: "right",

                xField6: 265 - (showFees.value ? 65 : 20),
                pField6: "right",

                xField7: 295 - (showFees.value ? 75 : 20),
                pField7: "right"
            };

            if (showFees.value) {
                positions.xField8 = 245;
                positions.pField8 = "right";

                positions.xField9 = 270;
                positions.pField9 = "right";
            }

            var pdfData = [];
            dataReport.value.forEach((item) => {
                var row = {
                    Date__Time: item.timestampDisplay,
                    Sale__Type: Utilities.toCartProcessingTypesDisplayName(item.saleType),
                    Location: item.location,
                    Item: item.item,
                    Cash: Utilities.toCurrency(item.cash),
                    Credit: Utilities.toCurrency(item.creditCard),
                    Total: Utilities.toCurrency(item.cash + item.creditCard)
                };
                if (showFees.value) {
                    row.Fees = Utilities.toCurrency(item.serviceFeeCreditCardAmount);
                    row.Net__Total = Utilities.toCurrency(item.cash + item.creditCard - item.serviceFeeCreditCardAmount);
                }
                

                pdfData.push(row);
            });

            var row = {
                Date__Time: "TOTALS",
                Sale__Type: "",
                Location: "",
                Item: "",
                Cash: Utilities.toCurrency(totalRow.value.cash),
                Credit: Utilities.toCurrency(totalRow.value.creditCard),
                Total: Utilities.toCurrency(totalRow.value.cash + totalRow.value.creditCard),
            };

            if (showFees.value) {
                row.Fees = Utilities.toCurrency(totalRow.value.serviceFeeCreditCardAmount);
                row.Net__Total = Utilities.toCurrency(totalRow.value.cash + totalRow.value.creditCard - totalRow.value.serviceFeeCreditCardAmount);
            }
            
            pdfData.push(row);           

            var pdfHeler = new PdfHelper();
            pdfHeler.savePdf(pdfData, positions, reportTitle.value, reportfilename.value, storage.getReportDateStart(), storage.getReportDateEnd(), "landscape", fontAdjust);
        };

        return {
            reportTitle,

            dataReport,
            onClose,
            onView,

            savePdf,
            exportCsv,
            Utilities,
            totalRow,
            showFees
        };
    }
};
</script>

<style scoped>
.report-modal {
    width: 90%;
    height: 80%;
    margin: 100px auto;
    background: white;
    /* border-radius: 10px; */
}
</style>
