<template>
    <div class="header-bar">
        <div style="display:flex; flex-direction:row;justify-content:flex-start">
            <div style="margin:5px"><img id="header_image_logo" @click="goHome()" src="../../../assets/logoMedium.png" style="height:40px;padding:0px;cursor:pointer" title="Back to Main Page" /></div>
            <div v-if="!portalUser.isSuperUser && !portalUser.isManager" style="padding-left:10px">
                <div style="display:flex; flex-direction:row;justify-content:flex-end">
                    <div style="display:flex; justify-content:center;flex-direction:column;margin: 10px;">
                        <img src="/images/icons/hamburger.png" style="width:30px;height:30px;margin:3px;cursor: pointer" @mouseenter="showMenu = true" />
                        <div class="header-menu-container" v-if="showMenu" @mouseleave="showMenu = false">
                            <div class="header-menu-title">
                                <div class="header-menu-item" style="font-size: 14pt;margin-left:0px" @click="goHome()">
                                    Home
                                </div>
                            </div>


                            <div class="header-menu-title" style="margin-top: 20px;" v-if="appHotkeys.length > 0">
                                <span>Applications</span>
                                <div v-for="hotkey in appHotkeys" :key="hotkey.key" class="header-menu-item" @click="gotoPage(hotkey.key)">
                                    {{ hotkey.value }}
                                </div>
                            </div>

                            <div class="header-menu-title" style="margin-top: 20px;" v-if="systemHotkeys.length > 0">
                                <span>System Configuration</span>
                                <div v-for="hotkey in systemHotkeys" :key="hotkey.key" class="header-menu-item" @click="gotoPage(hotkey.key)">
                                    {{ hotkey.value }}
                                </div>                                
                            </div>

                            <div class="header-menu-title" style="margin-top: 20px;" v-if="posHotkeys.length > 0">
                                <span>Point of Sale Configuration</span>
                                <div v-for="hotkey in posHotkeys" :key="hotkey.key" class="header-menu-item" @click="gotoPage(hotkey.key)">
                                    {{ hotkey.value }}
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="false" style="margin:20px;font-size:14pt">{{ Title }}</div>
        <div v-if="!noSignOut" style="padding-right:10px">
            <div style="display:flex; flex-direction:row;justify-content:flex-end">
                <div style="display:flex; justify-content:flex-end">
                    <img src="../../../assets/profile.png" style="width:48px;height:48px;margin:3px;cursor: pointer" @click="toggleProfile()" />
                </div>
            </div>
        </div>
        <!-- <div v-if="isProcessing" class="spin"></div> -->
    </div>

    <div v-if="showProcessing" class="header-loading">
        <div class="header-loading-inner">
            {{ processingMessage }}
        </div>
    </div>
    <div class="title" v-show="Title.length != 0">
        <div style="height:100%">
            <div style="margin:auto">
                <img :src="iconPath" style="width:20px;margin-top:3px;padding-right:10px" v-show="iconPath != undefined" />
            </div>
        </div>
        <div class="title-label">
            {{ Title }}
        </div>
    </div>
    <Profile v-if="showProfile" @close="toggleProfile" @on-reset-password="onChangePassword" />
    <AlertBox :caption="alertBox_caption" :message="alertBox_Message" v-if="alertBox_show" @on-ok="onClose" />
    <PinLookup v-if="pinLookup_show" @on-ok="onClose" :pincodeModel="pincodeModelX" />
    <MessageBoxV2 v-if="showMessageBoxModel" :messageBoxModel="messageBoxModel" @on-close="onClose" />
    <ChangePassword v-if="showChangePassword" @on-close="onClose" />
</template>

<script>
import { useRouter } from "vue-router";
import { ref } from "@vue/reactivity";
import Profile from "@/views/components/Shared/Profile";
import { getCurrentInstance, onMounted } from "@vue/runtime-core";
import AlertBox from "@/views/components/Shared/AlertBox";
import PinLookup from "@/views/components/Shared/PinLookup";
import Helper from "@/common/helper";
import MessageBoxV2 from "@/views/components/Shared/MessageBoxV2";
import ChangePassword from "@/views/components/Shared/ChangePassword";
import PortalUserModel from "@/common/models/PortalUserModel";

export default {
    components: {
        Profile,
        AlertBox,
        PinLookup,
        MessageBoxV2,
        ChangePassword
    },
    props: ["Title", "noSignOut", "iconPath"],
    setup() {
        var token = localStorage.getItem("token");

        const showProcessing = ref(false);
        const processingMessage = ref("");

        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const alertBox_caption = ref("");
        const alertBox_Message = ref("");
        const alertBox_show = ref(false);

        const pinLookup_show = ref(false);
        const pincodeModelX = ref();

        const showMessageBoxModel = ref(false);
        const messageBoxModel = ref(null);
        const showMenu = ref(false);

        const showChangePassword = ref(false);

        const portalUser = ref(null);
        portalUser.value = PortalUserModel.fromToken();

        const appHotkeys = ref([]);
        const posHotkeys = ref([]);
        const systemHotkeys = ref([]);

        onMounted(() => {
            getMenu();
        });

        emitter.on("server-call-start", (message) => {
            showProcessing.value = true;
            processingMessage.value = message;
        });

        emitter.on("server-call-stop", () => {
            showProcessing.value = false;
            processingMessage.value = "";
        });

        emitter.on("show-alert", (params) => {
            alertBox_caption.value = params[0];

            if (params[1].title === undefined) alertBox_Message.value = params[1];
            else {
                if (params[1].title === "Validation errors occurred.") {
                    alertBox_Message.value = params[1].errors;
                } else if (params[1].title === "One or more validation errors occurred.") {
                    var values = Object.values(params[1].errors);
                    var errors = "";
                    values.forEach((item) => {
                        var errorMessage = JSON.stringify(item)
                            .replaceAll("[", "")
                            .replaceAll("]", "")
                            .replaceAll('"', "");

                        errors += "- " + errorMessage + "\n\n";
                    });
                    alertBox_Message.value = errors;
                } else {
                    alertBox_Message.value = params[1].title;
                }
            }
            alertBox_show.value = true;
        });

        emitter.on("print-pin-ticket", (pinTicketModel) => {
            Helper.printPinTicket(pinTicketModel, window);
        });

        emitter.on("lookup-pin-ticket", (params) => {
            pincodeModelX.value = params;
            pinLookup_show.value = true;
        });

        emitter.on("show-message-box", (params) => {
            messageBoxModel.value = params;
            showMessageBoxModel.value = true;
        });

        const showProfile = ref(false);

        const router = useRouter();
        const goHome = () => {
            if (token !== null) router.push({ name: "Home" });
        };

        const toggleProfile = () => {
            showProfile.value = !showProfile.value;
        };

        const onClose = () => {
            alertBox_show.value = false;
            alertBox_caption.value = "";
            alertBox_Message.value = "";
            pinLookup_show.value = false;
            showMessageBoxModel.value = false;
            // showMemberLookup.value = false;
            showChangePassword.value = false;
        };

        const onChangePassword = () => {
            showChangePassword.value = true;
        };

        const getMenu = () => {
            appHotkeys.value = [];
            posHotkeys.value = [];
            systemHotkeys.value = [];
            portalUser.value = PortalUserModel.fromToken();
            console.log(portalUser.value)
            if (portalUser.value === null)
                return;

            if (!portalUser.value.isSuperUser && !portalUser.value.isManager) {
                if (!portalUser.value.enablePos) appHotkeys.value.push({ value: "Cashier", key: "Cashier" });
            }

            if (portalUser.value.enablePos) {
                appHotkeys.value.push({ value: "Point Of Sale", key: "PointOfSale" });
            }

            if ((portalUser.value.isAdmin || portalUser.value.isAdminPlus) && portalUser.value.enablePos && portalUser.value.enableInventoryManagement && !portalUser.value.disableInventoryManagement) {
                posHotkeys.value.push({ value: "Inventory", key: "Inventory" });
                posHotkeys.value.push({ value: "Tax Rates", key: "TaxRates" });
                posHotkeys.value.push({ value: "Menu Groups", key: "Groups" });
            }

            if (portalUser.value.isAdmin || portalUser.value.isAdminPlus) {
                if (!portalUser.value.disableCustomerManagement) appHotkeys.value.push({ value: "Members", key: "Member-Management" });

                if (!portalUser.value.disablePinTicket) appHotkeys.value.push({ value: "Batch Pin Codes", key: "Batch-PinCodes" });

                appHotkeys.value.push({ value: "Reporting", key: "Reporting" });

                if (!portalUser.value.disableUserRights) systemHotkeys.value.push({ value: "User Management", key: "User-Management" });

                if (!portalUser.value.disableCustomerCategory) systemHotkeys.value.push({ value: "Category Management", key: "Category" });

                if (!portalUser.value.disableHotKeys) systemHotkeys.value.push({ value: "Hotkey Management", key: "Hotkeys" });

                if (!portalUser.value.disablePreferences || !portalUser.value.disableKiosk || !portalUser.value.disablePinTicket) systemHotkeys.value.push({ value: "Settings", key: "Settings" });
            }
            
        };

        const gotoPage = (page) => {
            router.push({ name: page });
        }

        return {
            goHome,
            toggleProfile,
            showProfile,
            showProcessing,
            processingMessage,
            alertBox_caption,
            alertBox_Message,
            alertBox_show,
            onClose,

            pinLookup_show,
            pincodeModelX,

            showMessageBoxModel,
            messageBoxModel,

            onChangePassword,
            showChangePassword,

            showMenu,
            portalUser,
            appHotkeys,
            posHotkeys,
            systemHotkeys,
            gotoPage
        };
    }
};
</script>

<style scoped>
.header-bar {
    display: flex;
    background-color: #38b449;
    color: black;
    padding: 0px;
    /* min-width: 1000px; */
    width: 100%;
    height: 50px;
    justify-content: space-between;
}

.title {
    display: flex;
    flex-direction: row;
    height: 25px;
    background-color: white;
    color: black;
    font-size: 12pt;
    /* border-bottom: 1px solid #F19527; */
    padding: 5px 20px;
    text-transform: uppercase;
}

.title-label {
    margin: auto 0px ;
}

.error-alert {
    position: absolute;
    top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 999;
}

.error-alert-inner {
    background: crimson;
    color: white;
    text-align: center;
    width: 20%;
    border: 1px solid black;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
}

.spin {
    display: block;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border: 1px solid transparent;
    border-radius: 50%;
    border-top-color: white;
    animation: spin 1s linear infinite;
    align-self: center;
}

.header-loading {
    position: absolute;
    top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 999;
}

.header-loading-inner {
    color: white;
    background: #056608;
    text-align: center;
    width: 20%;
    border: 1px solid black;
    border-bottom-left-radius: 65px;
    border-bottom-right-radius: 65px;
}

.user-info {
    font-size: 8pt;
    color: white;
}

@keyframes spin {
    to {
        transform: rotateZ(360deg);
    }
}

.header-menu-container {
    text-align: left;
    background-color: #38b449;
    z-index: 9999;
    padding: 10px;
    color: black;
    opacity: 0.9;
}

.header-menu-title {
    font-size: 14pt;
    opacity: 1;
    text-transform: uppercase;
    color: White
}

.header-menu-item {
    font-size: 12pt;
    /* margin-top: 10px; */
    padding: 10px;
    cursor: pointer;
    color: black;
}

.header-menu-item:hover {
    background: white;
    color: black;
}
</style>
