<template>
    <div class="template-base">
        <!-- <Header Title="Point of Sale" :iconPath="'/images/icons/pos.png'" class="div-header" /> -->
        <Header Title="" class="div-header" />
        <hr style="width:100%" v-if="false" />
        <CashierDialog v-show="displayCashier" />
        <Payment ref="refPayment" v-show="displayPayment" @close="closePayment" @complete-tender="completeTender" />
        <OrderDetails ref="refOrderDetails" v-show="showOrderDetails" @close="showOrderDetails = false" @on-receipt="openReceipt" @on-void="voidOrder" @on-refund="refundSelect" />
        <RefundSelect ref="refRefundSelect" v-show="showRefundSelect" @close="showRefundSelect = false" @on-refund="refundOrder" />
        <PaymentRefund ref="refPaymentRefund" v-show="showPaymentRefund" @close="showPaymentRefund = false" @complete-tender="completeTender" />
        <OrderHistoryDialog ref="refOrderHistory" v-show="displayOrders" @on-close="displayOrders = false" @open-receipt="openReceipt" @order-recall="orderRecall" @order-details="orderDetails" />
        <Selector v-show="selector_show" ref="refSelector" />
        <InputBox ref="refInputBox" v-show="showInputBox" @on-cancel="showInputBox = false" />
        <div class="select-container div-body" v-if="!fetchingData">
            <div class="pos-container">
                <!-- Terminal Name -->
                <div class="pos-terminal-name">
                    <!-- <img src="/images/icons/layout.png" style="height: 18px; cursor: pointer;" v-if="portalUser.isAdmin && cartItems.length == 0" @click="onDesigner(!inDesigner)"> -->
                    <div></div>
                    <span style="margin-right:5px"> {{ terminalModel?.terminalName }} </span>
                </div>

                <!-- POS Middle Section -->
                <div class="select-container-rows pos-cart-quickkey-container" :style="inDesigner ? 'height:100%;' : ''">
                    <!-- Cart -->
                    <Cart
                        v-show="!inDesigner"
                        ref="refCart"
                        style="height:100%;width: 30%;"
                        :editable="editable"
                        :cartItems="cartItems"
                        :taxes="taxes"
                        :bays="bays"
                        @delete-cart-item="removeCartItem"
                        @on-quantity-set="onQuantitySet"
                        @on-bay-set="onBaySet"
                        @on-price-set="onPriceSet"
                        @on-member-set="onMemberSet"
                        @on-gift-card-set="onGiftCardSet"
                    />

                    <!-- QuickKeys -->
                    <QuickKeys
                        :editable="editable"
                        ref="refQuickKeys"
                        :style="'height:100%;width: ' + (inDesigner ? '100' : '55') + '%;'"
                        :menuGroupItems="menuGroupItems"
                        :inventoryList="inventoryItems"
                        @item-selected="itemSelected"
                        @on-cashier-show="displayCashier = true"
                        :propagateColors="posSettings.propagateColors"
                        :hotkeyColors="hotkeyColors"
                        @on-add-menu-group="addMenuGroup"
                        @on-add-hotkey="addHotkey"
                        @on-add-inventory="addInventory"
                        @on-update-menu-group="updateMenuGroup"
                        @on-update-inventory="updateInventory"
                        @on-delete-menu-group="deleteMenuGroup"
                        @on-delete-inventory="deleteInventory"
                        @on-add-spacer="addSpacer"
                        @on-edit-layout="onDesigner"
                        :cartItems="cartItems"
                    />

                    <!-- Status -->
                    <div class="pos-status-container" v-if="!inDesigner">
                        <div v-for="status in stationStatus" :key="status.stationId" class="pos-status" :title="'Last contact ' + status.timestampDisplay">
                            <div class="pos-station-name">{{ status.stationName }}</div>
                            <div v-if="status.canDisable">
                                <ToggleButton
                                    :text="!status.disabled ? 'On' : 'Off'"
                                    v-model="status.disabled"
                                    style="margin-left: 5px;width:50px"
                                    :title="!status.disabled ? 'Click to disable station' : 'Click to enable station'"
                                    :keyId="status.stationId"
                                    @toggled="enableStation"
                                ></ToggleButton>
                            </div>
                            <div v-if="status.canDisable" style="margin-right:55px"></div>
                            <div v-if="status.statusDisplay" class="pos-station"><div class="pos-station-up"></div></div>
                            <div v-if="!status.statusDisplay" class="pos-station"><div class="pos-station-down"></div></div>
                        </div>
                    </div>
                </div>

                <!-- Bottom Bar -->
                <div class="pos-command-bar" v-show="!inDesigner">
                    <!--
                         @on-order-history-show="displayOrders=true" 
                         @on-order-history-show="refundSelect"
                        -->
                    <CommandBar
                        ref="refCommandBar"
                        @on-delete="onDelete"
                        @on-clear="onClear"
                        :cartItems="cartItems"
                        :editable="editable"
                        @on-payment-show="onPayment"
                        @on-order-history-show="displayOrders = true"
                        @on-order-store="storeOrder"
                        @on-open-order="orderDetailsWithCartkey"
                        @show-member-orders="showMemberOrders"
                        @on-designer="onDesigner"
                    />
                </div>
            </div>
        </div>
    </div>
    <iframe ref="refPrintFrame" src="" frameborder="0" width="0px" height="0px" id="pos_print_frame"></iframe>
</template>

<script>
import axios from "axios";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import InventoryItemModel from "@/common/models/InventoryItemModel";
import CartModel from "@/common/models/CartModel";
import CartItemModel from "@/common/models/CartItemModel";
import Header from "@/views/components/Shared/Header";
import Cart from "@/views/components/PointOfSale/Cart";
import QuickKeys from "@/views/components/PointOfSale/QuickKeys";
import CommandBar from "@/views/components/PointOfSale/CommandBar";
import CashierDialog from "@/views/components/PointOfSale/CashierDialog";
import Payment from "@/views/components/PointOfSale/Payment";
import OrderHistoryDialog from "@/views/components/PointOfSale/OrderHistoryDialog";
import { ref } from "vue";
import { getCurrentInstance, onBeforeUnmount, onMounted } from "@vue/runtime-core";
import apiInventory from "@/api/apiInventory";
import apiTax from "@/api/apiTax";
import apiStation from "@/api/apiStation";
import apiMenuGroup from "@/api/apiMenuGroup";
import apiTerminal from "@/api/apiTerminal";
import apiCart from "@/api/apiCart";
import { CartStatusTypes, InventoryTypes, CartProcessingTypes, PosCardReaderTypes } from "@/common/enums";
import MessageBoxModel from "@/common/models/MessageBoxModel";
import OrderDetails from "@/views/components/PointOfSale/OrderDetails";
import PaymentRefund from "@/views/components/PointOfSale/PaymentRefund";
import RefundSelect from "@/views/components/PointOfSale/RefundSelect";
import PortalUserModel from "@/common/models/PortalUserModel";
import MenuGroupModel from "@/common/models/MenuGroupModel";
import InventoryModel from "@/common/models/InventoryModel";
import Selector from "@/views/components/Shared/Selector";
import apiMember from "@/api/apiMember";
import apiSettings from "@/api/apiSettings";
import InputBox from "@/views/components/Shared/InputBox";
import ToggleButton from "@/views/components/Shared/ToggleButton";
import apiHotkey from "@/api/apiHotkey";

export default {
    components: { Header, Cart, QuickKeys, CommandBar, CashierDialog, Payment, OrderHistoryDialog, OrderDetails, PaymentRefund, RefundSelect, Selector, InputBox, ToggleButton },
    setup() {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);

        const displayCashier = ref(false);
        const displayPayment = ref(false);
        const displayOrders = ref(false);

        const refCart = ref(null);

        const taxes = ref([]);
        const bays = ref([]);
        const inventoryItems = ref([]);
        const menuGroupItems = ref([]);
        const posSettings = ref([]);
        const hotkeyColors = ref([]);
        const cartItems = ref([]);
        const refPayment = ref(null);
        const refOrderDetails = ref(null);
        const refPaymentRefund = ref(null);
        const refRefundSelect = ref(null);
        const refQuickKeys = ref(null);
        const refPrintFrame = ref(null);
        const currentCartKey = ref(null);
        const editable = ref(true);
        const showOrderDetails = ref(false);
        const showPaymentRefund = ref(false);
        const showRefundSelect = ref(false);
        const stationStatus = ref([]);
        const statusInterval = ref();
        const refSelector = ref(null);
        const selector_show = ref(false);
        const terminalModel = ref(null);
        const refInputBox = ref(null);
        const showInputBox = ref(false);
        const refOrderHistory = ref(null);
        const inDesigner = ref(false);
        const refCommandBar = ref(null);

        const router = useRouter();
        var portalUser = ref(PortalUserModel.fromToken());

        const creditCardFeeSettings = ref(null)

        var isFalse = false;
        if (isFalse) {
            onBeforeRouteLeave((/*to, from*/) => {
                if (displayPayment.value) {
                    //const answer = window.confirm("Do you really want to leave? Sale not complete!");
                    // if (!answer) return false;
                    alert("Cannot leave page while processing payment");
                    return false;
                }
            });

            window.onbeforeunload = () => {
                if (displayPayment.value) {
                    // const answer = window.confirm("Do you really want to leave? Sale not complete!");
                    // if (!answer) return false;
                    alert("Cannot leave page while processing payment");
                    return false;
                }
            };
        }

        onBeforeRouteLeave((/*to, from*/) => {
            if (inDesigner.value) {
                 const answer = window.confirm("Do you really want to leave? Screen layout not saved.");                
                if (!answer) return false;
            }
        });

        window.onbeforeunload = () => {
            if (inDesigner.value) {
                const answer = window.confirm("Do you really want to leave? Screen layout not saved.");                
                if (!answer) return false;
            }
        };

        onMounted(async () => {
            terminalSelect();
            // await restoreCartLast();
            // statusInterval.value = window.setInterval(getStationStatus, 60000);
        });

        onBeforeUnmount(() => {
            window.clearInterval(statusInterval.value);
        });
        onBeforeUnmount;

        const terminalSelect = async () => {
            var terminal = localStorage.getItem("terminal");
            if (terminal !== null) {
                terminalModel.value = JSON.parse(terminal);
                await loadPos();
                return;
            }

            var terminals = await apiTerminal.getAll();

            if (terminals.length == 0) {
                alert("No terminals in system");
                router.push({ name: "Home" });
                return;
            }

            refSelector.value.showDialog(
                terminals,
                "Select Terminal Name",
                "Select name of this terminal. Anyone else using this terminal name will be signed out.",
                async (item) => {
                    await terminalSelected(item);
                },
                () => {
                    router.push({ name: "Home" });
                    return (selector_show.value = false);
                }
            );
            selector_show.value = true;
        };

        const terminalSelected = async (item) => {
            if (item == null) {
                alert("No terminal selected");
                router.push({ name: "Home" });
                return;
            }
            terminalModel.value = item;

            selector_show.value = false;

            try {
                emitter.emit("server-call-start", "Loading terminal...");
                await apiTerminal.markActive(item.globalId);

                localStorage.setItem("terminal", JSON.stringify(item));

                await loadPos();
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading terminal", err]);
            } finally {
                emitter.emit("server-call-stop");
            }
        };

        const loadPos = async () => {
            await restoreCartLast();
            statusInterval.value = window.setInterval(getStationStatus, 60000);
        };

        const restoreCartLast = async () => {
            fetchingData.value = true;
            try {
                await getData();
                restoreCart();
                if (currentCartKey.value !== null && currentCartKey.value !== "null") {
                    var cartModel = await getCart();
                    if (cartModel != null) {
                        refCart.value.refCartTotals.cartMember = cartModel.member;
                        if (cartModel.cartStatus == CartStatusTypes.Processing || cartModel.cartStatus == CartStatusTypes.Paid) {
                            await refPayment.value.initWindow(cartModel);
                            displayPayment.value = true;
                        }
                    }
                } else {
                    currentCartKey.value = null;
                }
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            fetchingData.value = false;
        };

        const getData = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                fetchingData.value = true;
                posSettings.value = await apiSettings.getPosSettings();
                menuGroupItems.value = await apiMenuGroup.getAll(true, true);
                taxes.value = await apiTax.getAll();
                bays.value = await apiStation.getAllTBoxes();
                inventoryItems.value = await apiInventory.getAll(true, true);
                //inventoryItems.value = inventoryItems.value.filter((x) => !x.isDisable);
                hotkeyColors.value = await apiSettings.getHotkeyColors();
                creditCardFeeSettings.value = await apiSettings.getCreditCardFees();

                // Remove Hotkey's if it's empty
                var hotkeyGroup = menuGroupItems.value.filter((x) => x.globalId === "00000000-0000-0000-0000-000000000142")[0];
                var anyItemsInGroup = inventoryItems.value.filter((x) => x.menuGroupId === "00000000-0000-0000-0000-000000000142").length > 0;
                if (!anyItemsInGroup) {
                    var index = menuGroupItems.value.indexOf(hotkeyGroup);
                    menuGroupItems.value.splice(index, 1);
                }

                await getStationStatus();
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const removeCartItem = (clientId) => {
            var index = cartItems.value.findIndex((item) => item.clientId === clientId);
            if (index > -1) {
                cartItems.value.splice(index, 1);
            }
            persistCart();
        };

        const onMemberSet = (clientId, member) => {
            var index = cartItems.value.findIndex((item) => item.clientId === clientId);
            if (index > -1) {
                cartItems.value[index].member = member;
            }
        };

        const onGiftCardSet = (clientId, giftCardNumber) => {
            var index = cartItems.value.findIndex((item) => item.clientId === clientId);
            if (index > -1) {
                cartItems.value[index].giftCardNumber = giftCardNumber;
            }
        };

        const itemSelected = (item) => {
            let overridePrice = null;
            if (item.isOpenKey) {
                refInputBox.value.initWindowNumeric(
                    "Enter sales price",
                    "",
                    async (salesPrice) => {
                        overridePrice = Number(salesPrice);
                        if (overridePrice < 0) {
                            showInvalidMessage("Price Error", "Pice cannot be negative");
                            return;
                        }

                        itemSelected2(item, overridePrice);
                    },
                    item.salesPrice,
                    0,
                    99999
                );
                showInputBox.value = true;
            } else {
                itemSelected2(item, item.salesPrice);
            }
        };

        const itemSelected2 = (item, overridePrice) => {
            // Check if item is already in cart
            var index = cartItems.value.findIndex((x) => x.inventoryItem.globalId == item.globalId && x.inventoryItem.salesPrice == overridePrice);
            if (item.inventoryType == InventoryTypes.PinTicket) {
                cartItems.value.push(new InventoryItemModel(item, 1, overridePrice));
            } else if (!item.noQty && index >= 0) {
                cartItems.value[index].quantity++;
            } else {
                cartItems.value.push(new InventoryItemModel(item, 1, overridePrice));
            }
            persistCart();
        };

        const onQuantitySet = (clientId, qty) => {
            var index = cartItems.value.findIndex((item) => item.clientId === clientId);
            if (index >= 0) {
                cartItems.value[index].quantity = qty;
            }
            persistCart();
        };

        const onPriceSet = (clientId, price, priceChangeKey) => {
            var index = cartItems.value.findIndex((item) => item.clientId === clientId);
            if (index >= 0) {
                cartItems.value[index].inventoryItem.salesPrice = price;
                cartItems.value[index].inventoryItem.priceChangeKey = priceChangeKey;
            }
            persistCart();
        };

        const onBaySet = (clientId, bay) => {
            var index = cartItems.value.findIndex((item) => item.clientId === clientId);
            if (index >= 0) {
                console.log("bay set", bay);
                cartItems.value[index].bay = bay;
            }
            persistCart();
        };

        const onDelete = async () => {
            if (currentCartKey.value !== null && currentCartKey.value !== "null") await apiCart.markDeleted(currentCartKey.value);
            clearCart();
        };

        const onClear = async () => {
            clearCart();
        };

        const clearCart = () => {
            cartItems.value = [];
            currentCartKey.value = null;
            editable.value = true;
            refCart.value.refCartTotals.cartMember = null;
            persistCart();
            refQuickKeys.value.homeClick();
        };

        const persistCart = () => {
            localStorage.setItem("currentCartKey", currentCartKey.value);
            localStorage.setItem("posCart", JSON.stringify(cartItems.value));
            persistCartWithValues(currentCartKey.value, JSON.stringify(cartItems.value));
        };

        const persistCartWithValues = (cartKey, cartItemsJson) => {
            localStorage.setItem("currentCartKey", cartKey);
            localStorage.setItem("posCart", cartItemsJson);
        };

        const restoreCart = () => {
            currentCartKey.value = localStorage.getItem("currentCartKey");
            var cart = localStorage.getItem("posCart");
            if (cart !== null && cart !== "null" && cart !== "undefined" && cart !== undefined) {
                try {
                    var items = JSON.parse(cart);
                    items.forEach((x) => {
                        var item = InventoryItemModel.fromObj(x);
                        cartItems.value.push(item);
                    });
                } catch {
                    clearCart();
                }
            }
        };

        const onPayment = async () => {
            var cartModel = null;
            var passed = await validateCart();

            if (passed) {
                cartModel = await refreshCart();
                passed = cartModel != null;
            }

            if (passed) {
                await refPayment.value.initWindow(cartModel);
                displayPayment.value = true;
            }
        };

        const closePayment = (renewCart) => {
            editable.value = true;
            displayPayment.value = false;
            if (renewCart) {
                currentCartKey.value = null;
                localStorage.setItem("currentCartKey", null);
            }
        };

        const validateCart = async () => {
            try {
                var usedBays = [];
                emitter.emit("server-call-start", "Validating cart...");
                var passed = true;

                for await (let i of cartItems.value) {
                    // Valid reload has member assigned
                    if (i.inventoryItem.inventoryType == InventoryTypes.Reload && i.member == null) {
                        showInvalidMessage("Member Not Selected", `Cart item "${i.inventoryItem.inventoryName}" does not have a member selected.`);
                        passed = false;
                        return;
                    }

                    // Valid open range has member assigned
                    if (i.inventoryItem.inventoryType == InventoryTypes.OpenRange && i.member == null) {
                        showInvalidMessage("Member Not Selected", `Cart item "${i.inventoryItem.inventoryName}" does not have a member selected.`);
                        passed = false;
                        return;
                    }

                    // Valid open range has value assigned
                    if (i.inventoryItem.inventoryType == InventoryTypes.OpenRange && i.inventoryItem.salesPrice <= 0) {
                        showInvalidMessage("Value not set", `Cart item "${i.inventoryItem.inventoryName}" does not have valid sales price.`);
                        passed = false;
                        return;
                    }

                    // Valid gift card
                    if (i.inventoryItem.inventoryType == InventoryTypes.GiftCard) {
                        if (i.giftCardNumber == null) {
                            showInvalidMessage("Gift Card Not Swiped", `Cart item "${i.inventoryItem.inventoryName}" does not have a gift card associated.`);
                            passed = false;
                            return;
                        }

                        if (!posSettings.value.linkRangeGift) var memberNumber = await apiMember.getByMemberCardAll(i.giftCardNumber);
                        if (memberNumber != null) {
                            showInvalidMessage("Invalid Gift Card", `Cart item "${i.inventoryItem.inventoryName}" is not assigned to a gift card.`);
                            passed = false;
                            return;
                        }
                    }

                    // Validate TBox has bays assigned
                    if (i.inventoryItem.inventoryType == InventoryTypes.TBox) {
                        if (i.bay == null || i.bay == "null") {
                            showInvalidMessage("Bay Not Selected", `Cart item "${i.inventoryItem.inventoryName}" does not have a bay selected.`);
                            passed = false;
                            return;
                        }

                        // Validate Bay is not assigned more than once
                        if (usedBays.filter((x) => x == i.bay.stationName).length > 0) {
                            showInvalidMessage("Multiple Bay Assignments", `"${i.bay.stationName}" cannot be assigned more than once.`);
                            passed = false;
                            return;
                        }
                        usedBays.push(i.bay.stationName);

                        // Validate TBox can be started
                        var isAvailable = await apiStation.isTboxAvailable(i.bay.globalId);
                        if (!isAvailable) {
                            showInvalidMessage("Bay Not Available", `Bay ${i.bay.stationName} is not available.`);
                            passed = false;
                            return;
                        }
                    }
                }

                emitter.emit("server-call-stop");
                return passed;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error validating cart", err]);
                emitter.emit("server-call-stop");
                return false;
            } finally {
                emitter.emit("server-call-stop");
            }
        };

        const refreshCart = async () => {
            var retValue = null;

            var existingCart = await getCart();

            var cartModel = new CartModel();
            cartModel.CartProcessingType = CartProcessingTypes.Sale;
            if (existingCart !== null) cartModel.cartStatus = existingCart.cartStatus;
            else cartModel.cartStatus = CartStatusTypes.NotStarted;
            cartModel.cartKey = currentCartKey.value;
            cartModel.itemTotal = refCart.value.refCartTotals.totalPriceValue;
            cartModel.taxTotal = refCart.value.refCartTotals.totalTaxValue;
            cartModel.grandTotal = refCart.value.refCartTotals.totalGrandValue;
            cartModel.member = refCart.value.refCartTotals.cartMember;
            cartModel.posCart = JSON.stringify(cartItems.value);
            for (let i of cartItems.value) {
                var cartItem = new CartItemModel();
                cartItem.inventoryId = i.inventoryItem.globalId;
                cartItem.inventoryName = i.inventoryItem.inventoryName;
                cartItem.inventoryType = i.inventoryItem.inventoryType;
                cartItem.quantity = i.quantity;
                cartItem.price = i.inventoryItem.salesPrice;
                cartItem.isNonInventory = i.inventoryItem.isNonInventory;
                cartItem.tax = i.inventoryItem.taxRate;
                cartItem.priceChangeKey = i.inventoryItem.priceChangeKey;
                if (i.member != null) cartItem.memberId = i.member.globalId;
                if (i.bay != null) cartItem.bayStationId = i.bay.globalId;
                if (i.giftCardNumber != null) cartItem.giftCardNumber = i.giftCardNumber;

                cartModel.cartItems.push(cartItem);
            }
            cartModel.roundTotals();
            cartModel.feeRate = creditCardFeeSettings.value.rate
            currentCartKey.value = await saveCart(cartModel);
            cartModel.cartKey = currentCartKey.value;
            persistCart();
            retValue = cartModel;

            return retValue;
        };

        const saveCart = async (cartModel) => {
            var retValue = null;
            try {
                emitter.emit("server-call-start", "Saving cart...");
                retValue = await apiCart.saveCart(cartModel);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error saving cart", err]);
            }
            emitter.emit("server-call-stop");
            return retValue;
        };

        const getCart = async () => {
            if (currentCartKey.value === null) return null;
            var retValue = null;
            try {
                emitter.emit("server-call-start", "Loading cart...");
                var cartModel = await apiCart.getCart(currentCartKey.value);
                if (cartModel != null) {
                    editable.value = cartModel.cartStatus != CartStatusTypes.Processing && cartModel.cartStatus != CartStatusTypes.Paid;
                }

                retValue = cartModel;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading cart", err]);
            }
            emitter.emit("server-call-stop");

            return retValue;
        };

        const showInvalidMessage = (title, message) => {
            var messageBoxModel = new MessageBoxModel();
            messageBoxModel.caption = title;
            messageBoxModel.message.push(message);
            messageBoxModel.btnYes = false;
            messageBoxModel.btnNoText = "Close";
            emitter.emit("show-message-box", messageBoxModel);
        };

        const completeTender = async (cartKey, printReceiptMerchant = true, printReceiptCustomer = true) => {
            clearCart();
            viewReciept(cartKey, printReceiptMerchant, printReceiptCustomer);
        };

        const viewReciept = (cartKey, printReceiptMerchant = true, printReceiptCustomer = true) => {            
            var url = `pos-receipt.html?cartkey=${encodeURIComponent(cartKey)}&endpoint=${axios.defaults.baseURL}&printReceiptMerchant=${printReceiptMerchant}&printReceiptCustomer=${printReceiptCustomer}&locale=${portalUser.value.locale}&currency=${portalUser.value.currency}`;
            console.log(url)
            refPrintFrame.value.src = url;
            displayPayment.value = false;
            showPaymentRefund.value = false;
            refQuickKeys.value.homeClick();
        };

        const orderRecall = async (cartModel) => {
            persistCartWithValues(cartModel.cartKey, cartModel.posCart);
            if (cartModel.cartProcessingType == CartProcessingTypes.Refund) {
                await refundOrder(cartModel);
            } else {
                await restoreCartLast();
            }
        };

        const orderDetails = async (cartModel) => {
            orderDetailsWithCartkey(cartModel.cartKey);
        };

        const orderDetailsWithCartkey = async (cartKey) => {
            if (await refOrderDetails.value.showDetails(cartKey)) {
                showOrderDetails.value = true;
            }
        };

        const storeOrder = async () => {
            await refreshCart();
            clearCart();
        };

        const openReceipt = (cartModel) => {
            viewReciept(cartModel.cartKey);
        };

        const voidOrder = (cartModel) => {
            console.log("TODO: Void Order", cartModel);
        };

        const refundSelect = async (cartModel) => {
            showOrderDetails.value = false;
            showRefundSelect.value = true;
            await refRefundSelect.value.initWindow(cartModel);
        };

        const refundOrder = async (cartModel) => {
            showRefundSelect.value = false;

            var canRefund = terminalModel.value.posCardReaderType != PosCardReaderTypes.Bezel8;
            await refPaymentRefund.value.initWindow(cartModel, canRefund);
            showPaymentRefund.value = true;
        };

        const getStationStatus = async () => {
            try {
                stationStatus.value = await apiStation.getStationStatus(portalUser.value.tenantId);
            } catch (err) {
                console.log("ERROR:", err);
            }
        };

        const enableStation = async (stationId, status) => {
            await apiStation.setStationOperationStatus(stationId, status);
        };

        const showMemberOrders = async (member) => {
            await refOrderHistory.value.showMemberOrders(member);
            displayOrders.value = true;
        };

        const onDesigner = async (value) => {
            inDesigner.value = value;
            refCommandBar.value.inDesigner = value;

            if (!value) {
                fetchingData.value = true;
                try {
                    emitter.emit("server-call-start", "Saving layout...");
                    await apiInventory.saveScreenLayout(InventoryModel.toScreenLayoutModels(inventoryItems.value));
                    await apiMenuGroup.saveScreenLayout(MenuGroupModel.toScreenLayoutModels(menuGroupItems.value));
                } catch (err) {
                    console.log("ERROR:", err);
                    emitter.emit("show-alert", ["Error saving layout", err]);
                }
                emitter.emit("server-call-stop");
                fetchingData.value = false;
            }
        };

        const addMenuGroup = async (parentId) => {
            refInputBox.value.initWindow("Enter menu group name", "", 3, 20, async (itemName) => {
                try {
                    emitter.emit("server-call-start", "Creating item...");
                    var model = await apiMenuGroup.create(parentId, itemName);
                    menuGroupItems.value.push(model);
                    refQuickKeys.value.openMenuGroupSettings(model.globalId);
                } catch (err) {
                    console.log("ERROR:", err);
                    emitter.emit("show-alert", ["Error creating item", err]);
                }
                emitter.emit("server-call-stop");
            });
            showInputBox.value = true;
        };

        const addSpacer = async (parentId) => {
            try {
                emitter.emit("server-call-start", "Creating item...");
                var model = await apiMenuGroup.create(parentId, "spacer142");
                menuGroupItems.value.push(model);                
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error creating item", err]);
            }
            emitter.emit("server-call-stop");
        };

        const deleteMenuGroup = async (globalId) => {
            // Move inventory records in deleted menu group to Home group
            inventoryItems.value
                .filter((x) => x.menuGroupId == globalId)
                .forEach((item) => {
                    item.menuGroupId = "00000000-0000-0000-0000-000000000001";
                    item.displayOrder = 99999;
                    item.screenSection = 1;
                });

            // Move menu groups in deleted menu group to Home group
            menuGroupItems.value
                .filter((x) => x.parentId == globalId)
                .forEach((item) => {
                    item.parentId = "00000000-0000-0000-0000-000000000001";
                    item.displayOrder = 99999;
                    item.screenSection = 1;
                });

            var index = menuGroupItems.value.map((x) => x.globalId).indexOf(globalId);
            if (index > -1) menuGroupItems.value.splice(index, 1);
        };

        const deleteInventory = async (globalId) => {
            var index = inventoryItems.value.map((x) => x.globalId).indexOf(globalId);
            if (index > -1) inventoryItems.value.splice(index, 1);
        };

        const addHotkey = async (parentId) => {
            refInputBox.value.initWindow("Enter hotkey name", "", 3, 20, async (itemName) => {
                try {
                    emitter.emit("server-call-start", "Creating item...");
                    var model = await apiHotkey.createToInventory(itemName, parentId);
                    inventoryItems.value.push(model);
                    await refQuickKeys.value.openHotkeySettings(model.globalId);
                } catch (err) {
                    console.log("ERROR:", err);
                    emitter.emit("show-alert", ["Error creating item", err]);
                }
                emitter.emit("server-call-stop");
            });
            showInputBox.value = true;
        };

        const addInventory = async (parentId) => {
            refInputBox.value.initWindow("Enter inventory name", "", 3, 20, async (itemName) => {
                try {
                    emitter.emit("server-call-start", "Creating item...");
                    var model = await apiInventory.create(itemName);
                    model.menuGroupId = parentId;
                    model.displayOrder = 9999;
                    await apiInventory.save(model);
                    inventoryItems.value.push(model);
                    await refQuickKeys.value.openInventorySettings(model.globalId);
                } catch (err) {
                    console.log("ERROR:", err);
                    emitter.emit("show-alert", ["Error creating item", err]);
                }
                emitter.emit("server-call-stop");
            });
            showInputBox.value = true;
        };

        const updateMenuGroup = async (updatedItem) => {
            var targetItem = menuGroupItems.value.filter((x) => x.globalId === updatedItem.globalId)[0];
            targetItem.updateSelf(updatedItem);
        };

        const updateInventory = async (updatedItem) => {
            var index = inventoryItems.value.findIndex((item) => item.globalId === updatedItem.globalId);
            inventoryItems.value[index] = updatedItem;
        };

        return {
            itemSelected,
            cartItems,
            removeCartItem,
            onQuantitySet,
            inventoryItems,
            onDelete,
            onClear,
            taxes,
            bays,
            menuGroupItems,
            displayCashier,
            displayPayment,
            refCart,
            onPayment,
            refPayment,
            onBaySet,
            completeTender,
            refQuickKeys,
            refPrintFrame,
            openReceipt,
            orderRecall,
            displayOrders,
            storeOrder,
            editable,
            closePayment,
            orderDetails,
            showOrderDetails,
            orderDetailsWithCartkey,
            showPaymentRefund,
            showRefundSelect,
            voidOrder,
            refundOrder,
            refOrderDetails,
            refPaymentRefund,
            refRefundSelect,
            refundSelect,
            fetchingData,
            onPriceSet,
            onMemberSet,
            onGiftCardSet,
            stationStatus,
            selector_show,
            refSelector,
            terminalModel,
            refInputBox,
            showInputBox,
            posSettings,
            enableStation,
            refOrderHistory,
            showMemberOrders,
            onDesigner,
            inDesigner,
            hotkeyColors,
            portalUser,
            addMenuGroup,
            addHotkey,
            addInventory,
            updateMenuGroup,
            updateInventory,
            deleteMenuGroup,
            deleteInventory,
            refCommandBar,
            addSpacer
        };
    }
};
</script>

<style scoped>
.pos-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.pos-cart-quickkey-container {
    height: calc(100% - 150px - 19px);
    width: 100%;
    position: relative;
    margin-bottom: 150px;
}

.pos-command-bar {
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 150px;
}

.status-container {
    padding-top: 50px;
    font-size: 12pt;
    width: 20%;
    display: flex;
    flex-flow: column;
    background: rgb(218, 218, 218);
    /* border-left: 1px black solid; */
}

.pos-status {
    width: 90%;
    margin: 10px;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    text-align: left;
    flex-wrap: nowrap;
}

.pos-station-name {
    width: 80%;
}

.pos-station {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    margin: auto;
}

.pos-station-up {
    width: 12px;
    height: 12px;
    background: green;
    border-radius: 2px;
}

.pos-station-down {
    width: 12px;
    height: 12px;
    background: red;
    border-radius: 2px;
}

.pos-terminal-name {
    border-bottom: 1px black solid;
    text-align: right;
    color: #142142;
    font-size: 11pt;
    display: flex;
    justify-content: space-between;
}

.pos-status-container {
    font-size: 12pt;
    width: 14%;
    display: flex;
    flex-flow: column;
    background: white;
    height: 100%;
    /* border-left: 1px black solid; */
}
</style>
