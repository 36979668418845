import axios from "axios";
import ActionLogModel from "@/common/models/ActionLogModel"
import ExceptionLogModel from "@/common/models/ExceptionLogModel"
import StationTransactionDetailModel from "@/common/models/StationTransactionDetailModel"


export default class apiLogging {
    static async saleItem(hotkeyId: string): Promise<void> {
        await axios.post("api/Logging/SaleItem", {
            hotkeyId: hotkeyId
        });
    }

    static async reportFix(tenantId: string, day: Date): Promise<String> {
        var response = await axios.post("api/Logging/ReportFix", {
            tenantId : tenantId,
            day: day
        });
        return response.data.dataValues
    }

    static async reportFixMemberBalance(tenantId: string): Promise<any> {
        var response = await axios.post("api/Logging/ReportFixMemberBalance", {
            tenantId : tenantId,
        });

        return response.data
    }



    static async getStationTransactions(stationId: string, day: Date): Promise<Array<ActionLogModel>> {
        let models = new Array<ActionLogModel>();
        var response = await axios.post("api/Logging/GetStationTransactions", {
            stationId : stationId,
            date: day
        });

        response.data.actionLogs.forEach((item: any) =>
                models.push(ActionLogModel.from(item))
            );            

        return models;
    }

    static async getStationTransactionDetails(stationId: string, day: Date, transactionId: string): Promise<StationTransactionDetailModel> {
        var response = await axios.post("api/Logging/GetStationTransactionDetails", {
            stationId : stationId,
            date: day,
            transactionId: transactionId
        });

        return StationTransactionDetailModel.from(response.data)
    }
    
    static async getExceptions(day: Date): Promise<Array<ExceptionLogModel>> {
        let models = new Array<ExceptionLogModel>();
        var response = await axios.post("api/Logging/GetExceptions", {            
            date: day,            
        });

        response.data.exceptionLogs.forEach((item: any) =>
                models.push(ExceptionLogModel.from(item))
            );            

        return models;
    }
}