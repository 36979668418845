import Helper from "@/common/helper.js"

export default class StationStatusModel {
    tenantId: string | undefined;
    tenantName: string | undefined;
    stationId: string | undefined;
    stationName: string | undefined;    
    timestamp: string | undefined
    stationType: string | undefined;
    command: string = ""
    actionInfo: string | undefined;
    version: string | undefined;
    lastStart: number | undefined;
    lastTransaction: number | undefined;
    stationTimeDiff: number | undefined;
    disabled: boolean | undefined = false;
    platform: string | undefined;
    readerInfo: string | undefined;
    readerInfoDisplay: string | undefined;


    get timestampDisplay(): string {
        
        if (this.timestamp! == '0')
            return "Never"            
        let local = Helper.convertUTCDateToLocalDate(new Date(this.timestamp!))        
        return local.toDateString().substring(4) + ", " + local.toLocaleTimeString()
    }

    get lastStartDisplay(): string {
        
        if (this.lastStart! == 0)
            return "Never"            
        let local = Helper.convertUTCDateToLocalDate(new Date(this.lastStart!))        
        return local.toDateString().substring(4) + ", " + local.toLocaleTimeString()
    }    

    get lastTransactionDisplay(): string {
        
        if (this.lastTransaction! == 0)
            return "Never"            
        let local = Helper.convertUTCDateToLocalDate(new Date(this.lastTransaction!))        
        return local.toDateString().substring(4) + ", " + local.toLocaleTimeString()
    }

    get canDisable(): boolean {
        return this.stationType! == "Dispenser" || this.stationType! == "Turnstile"
    }


    get platformDisplay(): string {
        

        if ((this.platform! !== null) && (this.platform!.length > 0)) {
            if (this.platform?.startsWith("Windows"))
                return "Windows"
            else if (this.platform?.startsWith("Linux-4")) {
                return "Pi 3"
            }
            else if (this.platform?.startsWith("Linux-6")) {
                return "Pi 4"
            }
        }
            
        return this.platform!
    }  
    // get statusDisplay(): boolean {        
    //     let lastPing = Helper.convertUTCDateToLocalDate(new Date(this.timestamp!)).getTime()
    //     let now = new Date().getTime()
    //     let diff = Math.abs((lastPing - now)/1000)        
    //     return diff < (60) // 1 minute
    // }

    static from(json: string): StationStatusModel {
        let model = Object.assign(new StationStatusModel(), json);
        return model;
    }
}
