export default class LoginModel  {
    token: string = "";

    redirect: Boolean = false;
    redirectUrl: string = "";

    mfaRequired: Boolean = false;
    mfaQrCode: string = "";
    mfaSetupKey: string = "";
    mfaKey2: string = "";
    rememberSecret: string = "";
    allowRememberMe: boolean = false;

    static from(json: string): LoginModel {
        let model = Object.assign(new LoginModel(), json);
        return model;
    }
}
